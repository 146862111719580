import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import ThemeSelector from './ThemeSelector';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
    <React.StrictMode>
        <ThemeSelector/>
    </React.StrictMode>,
    document.getElementById('root')
);


serviceWorker.unregister();
