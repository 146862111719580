import React, {Suspense} from 'react';
import blueColorLoader from './assets/images/Loaders/BlueColorLoader.gif';
import purpleColorLoader from './assets/images/Loaders/PurpleColorLoader.gif';
import greenColorLoader from './assets/images/Loaders/GreenColorLoader.gif';
import orangeColorLoader from './assets/images/Loaders/OrangeColorLoader.gif';
import pinkColorLoader from './assets/images/Loaders/PinkColorLoader.gif';
import redColorLoader from './assets/images/Loaders/RedColorLoader.gif';
import brownColorLoader from './assets/images/Loaders/BrownColorLoader.gif';
import charcoalColorLoader from './assets/images/Loaders/CharcoalColorLoader.gif';

import {setFaviconAndMetaData} from "./utils";
import {Configurations as reactUrls} from './Configurations.js';

const BlueTheme = React.lazy(() => import('./themes/BlueTheme'));
const PurpleTheme = React.lazy(() => import('./themes/PurpleTheme'));
const OrangeTheme = React.lazy(() => import('./themes/OrangeTheme'));
const PinkTheme = React.lazy(() => import('./themes/PinkTheme'));
const GreenTheme = React.lazy(() => import('./themes/GreenTheme'));
const RedTheme = React.lazy(() => import('./themes/RedTheme'));
const BrownTheme = React.lazy(() => import('./themes/BrownTheme'));
const CharcoalTheme = React.lazy(() => import('./themes/CharcoalTheme'));


class ThemeSelector extends React.Component {

    componentWillMount() {
        let loc = window.location;
        if (loc.pathname !== "/") {
            window.location.href = window.location.origin;
        }
        let url = window.location.href;
        let elem = reactUrls && reactUrls.find(el => el.url === url);
        let data = {
            icon: elem && elem.icon,
            description: elem && elem.description,
            title: elem && elem.title
        }
        setFaviconAndMetaData(data);
    }

    getColorAndPCB() {
        let url = window.location.href;
        let elem = reactUrls && reactUrls.find(el => el.url === url);
        return {color: elem && elem.assets, id: elem && elem.Id}
    }

    render() {
        let colorAndPCB = this.getColorAndPCB();
        if (colorAndPCB.color === "Blue") {
            return (
                <div>
                    <Suspense fallback={<div className="progress_bar"><img src={blueColorLoader} alt=''/></div>}>
                        <BlueTheme PCBID={colorAndPCB.id} color={colorAndPCB.color}/>
                    </Suspense>
                </div>
            )
        } else if (colorAndPCB.color === "Purple") {
            return (
                <div>
                    <Suspense fallback={<div className="progress_bar"><img src={purpleColorLoader} alt=''/></div>}>
                        <PurpleTheme PCBID={colorAndPCB.id} color={colorAndPCB.color}/>
                    </Suspense>
                </div>
            )
        } else if (colorAndPCB.color === "Pink") {
            return (
                <div>
                    <Suspense fallback={<div className="progress_bar"><img src={pinkColorLoader} alt=''/></div>}>
                        <PinkTheme PCBID={colorAndPCB.id} color={colorAndPCB.color}/>
                    </Suspense>
                </div>
            )
        } else if (colorAndPCB.color === "Orange") {
            return (
                <div>
                    <Suspense fallback={<div className="progress_bar"><img src={orangeColorLoader} alt=''/></div>}>
                        <OrangeTheme PCBID={colorAndPCB.id} color={colorAndPCB.color}/>
                    </Suspense>
                </div>
            )
        } else if (colorAndPCB.color === "Green") {
            return (
                <div>
                    <Suspense fallback={<div className="progress_bar"><img src={greenColorLoader} alt=''/></div>}>
                        <GreenTheme PCBID={colorAndPCB.id} color={colorAndPCB.color}/>
                    </Suspense>
                </div>
            )
        } else if (colorAndPCB.color === "Red") {
            return (
                <div>
                    <Suspense fallback={<div className="progress_bar"><img src={redColorLoader} alt=''/></div>}>
                        <RedTheme PCBID={colorAndPCB.id} color={colorAndPCB.color}/>
                    </Suspense>
                </div>
            )
        } else if (colorAndPCB.color === "Brown") {
            return (
                <div>
                    <Suspense fallback={<div className="progress_bar"><img src={brownColorLoader} alt=''/></div>}>
                        <BrownTheme PCBID={colorAndPCB.id} color={colorAndPCB.color}/>
                    </Suspense>
                </div>
            )
        } else if (colorAndPCB.color === "Charcoal") {
            return (
                <div>
                    <Suspense fallback={<div className="progress_bar"><img src={charcoalColorLoader} alt=''/></div>}>
                        <CharcoalTheme PCBID={colorAndPCB.id} color={colorAndPCB.color}/>
                    </Suspense>
                </div>
            )
        }
    }
}

export default ThemeSelector;
