export const Configurations = [
    {
        assets: "Brown",
        url: "https://4pawsplayhouse.com/",
        Id: "PCB00108705",
        icon: "4PawsPlayHouse",
        title: "Dog Boarding and Grooming in Oak Forest Illinois | 4 Paws Playhouse",
        description: "4 Paws Playhouse is a full-service dog boarding, dog daycare, and dog grooming facility in Oak Forest, Illinois. We are located outside the Chicago area but are optimally placed for Illinois residents anywhere."
    },
    {
        assets: "Brown",
        url: "https://www.4pawsplayhouse.com/",
        Id: "PCB00108705",
        icon: "4PawsPlayHouse",
        title: "Dog Boarding and Grooming in Oak Forest Illinois | 4 Paws Playhouse",
        description: "4 Paws Playhouse is a full-service dog boarding, dog daycare, and dog grooming facility in Oak Forest, Illinois. We are located outside the Chicago area but are optimally placed for Illinois residents anywhere."
    },
    {
        assets: "Brown",
        url: "https://3dogfarm.com/",
        Id: "PCB00108697",
        icon: "3DogFarm",
        title: "Dog day camp, overnight boarding, training in Purcellville VA | 3 Dog Farm",
        description: "3 Dog Farm is operated by our team of animal care professionals with decades of experience in the areas of canine training, behavior modification, organized activities and competition, daycare, boarding rescue and just plain 3 Dog Farm fun. Serving Virginia and West Virginia."
    },
    {
        assets: "Brown",
        url: "https://www.3dogfarm.com/",
        Id: "PCB00108697",
        icon: "3DogFarm",
        title: "Dog day camp, overnight boarding, training in Purcellville VA | 3 Dog Farm",
        description: "3 Dog Farm is operated by our team of animal care professionals with decades of experience in the areas of canine training, behavior modification, organized activities and competition, daycare, boarding rescue and just plain 3 Dog Farm fun. Serving Virginia and West Virginia."
    }
]

