export function businessTitle(message) {
    if (message && message.is_enterprise && message.is_enterprise_label) {
        return message.message_label.name;
    } else {
        return message.business;
    }
}

export function businessLogo(message) {
    if (message && message.is_enterprise && message.is_enterprise_label) {
        return message.message_label.thumb_url;
    } else {
        return message.business_logo;
    }
}

export function setFaviconAndMetaData(data) {
    if (data && data.icon && data.icon !== typeof ('undefined') && data.icon !== 'undefined') {
        let favIconTag = document.querySelector("link[rel~='icon']");
        if (!favIconTag) {
            favIconTag = document.createElement('link');
            favIconTag.rel = 'icon';
            document.getElementsByTagName('head')[0].appendChild(favIconTag);
        }
        favIconTag.href = (require((`./assets/images/FavIcons/${data.icon}.ico`)));
    }

    document.title = data.title;
    document.head.querySelector('meta[name="description"]').content = data.description;
    document.head.querySelector('meta[name="title"]').content = data.title;
}

export function formatTimeAgo(timeInWords) {
    let timeAndRange = timeInWords.split(' ');
    let tym = timeAndRange[0];
    let range = timeAndRange[1];
    if (!tym || !range) {
        range = timeInWords.substr(timeInWords.length - 3);
        tym = timeInWords.slice(0, timeInWords.length - 3);
    }
    if (range == 'mins') {
        return tym + 'm';
    } else if (range == 'hrs' || range == 'h') {
        return tym + 'h';
    } else if (range == 'days' || range == 'd') {
        return tym + 'd';
    } else if (range == 'mth' || range == 'mths') {
        return tym + 'M';
    } else if (range == 'yrs' || range == 'yr') {
        return tym + 'y';
    }
}

export function findParent(element, className) {
    do {
        if (className == element.className) {
            return element;
        }
        element = element.parentNode;
    } while (element);
    return false;
}

export function findChild(element, tagName) {
    for (let childElement of element.children) {
        if (childElement.tagName === tagName) {
            return childElement;
        }
    }
    return false;
}

export function computeTotalSlides(totalSlides, slideCount) {
    const width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

    if (width < 992) {
        return 1;
    } else if (totalSlides < slideCount) {
        return totalSlides;
    } else {
        return slideCount;
    }
}

export function computeSliderCount(defaultValue) {
    const width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

    if (width < 992) {
        return 1;
    } else {
        return defaultValue;
    }
}

export function computeSliderArrows(length, slideCount) {
    const width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

    if (width < 992) {
        return length > 1
    } else {
        return length > slideCount
    }
}

export function updateAnalytics(type, result, PCBID) {
    let data = {type: type, entity_ids: result}
    fetch(process.env.REACT_APP_BASE_URL + 'web/' + PCBID + '/update_analytics', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(data)
    })
}
